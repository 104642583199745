import React, { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import "./calendar.scss";
import { Mixpanel } from '../../mixpanel';
import { isMobile } from 'react-device-detect';
import useCampaign from "./useCampaign";
import { useHistory, useParams } from "react-router-dom";
import { getConcatenatedStringOfFeaturesForAnalytics } from "../../abTesting";
import useUTMParams from './useUTMParams';

const CalendarWide = (props) => {
    const {
        handleEvent,
        getCampaignValue,
        getSourceType,
        campaignName
    } = useCampaign(props);

    const history = useHistory();
    const [showButtons, setShowButtons] = useState(false);
    const showCalendarButtons = () => {
        setShowButtons(true);
    }

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log(""),
        onDateAndTimeSelected: () => console.log(""),
        onEventTypeViewed: () => console.log(""),
        onEventScheduled: (e) => handleEvent(),
    });

    // const getUrl = () => {

    //     if (props['company-page']) {
    //         return 'https://calendly.com/talekroni/30min?hide_event_type_details=1&hide_gdpr_banner=1';
    //     } else if (props.companyId) {
    //         return 'https://calendly.com/d/ykb-5gr-r88/better?hide_gdpr_banner=1';
    //     } else
    //         return 'https://calendly.com/d/d4g-h5b-b33/meet-with-me?hide_event_type_details=1&hide_gdpr_banner=1&month=2023-04';
    // }

    const getUrl = () => {

        if (props['company-page']) {
            return 'https://calendly.com/ofra-erez/15min?hide_event_type_details=1&hide_gdpr_banner=1&month=2024-08';
        }
        else if (props.companyId) {
            return 'https://calendly.com/d/ykb-5gr-r88/better?hide_event_type_details=1&hide_gdpr_banner=1&month=2024-08';
        }

        return 'https://calendly.com/d/d56-qkn-h6z/better?hide_event_type_details=1&hide_gdpr_banner=1&month=2024-08';
    }
    
    const getStyles = () => {
        let styles = {}
        if (isMobile)
            styles = {  height: '80vh', width: '100%' }
        else
            styles = { height: '1000px', width: '610px' }
        if (props.styles)
            styles = { ...styles, ...props.styles }
        return styles;
    } 

    const utm={
        utmCampaign: getSourceType(),
        utmContent: Mixpanel.getId(),
        utmMedium: getConcatenatedStringOfFeaturesForAnalytics(['landingPageVersion', 'mainHomeImage']),
        utmSource: getCampaignValue(),
        utmTerm: campaignName
    }
    console.log(utm)

    const utmPararms = useUTMParams();
    console.log("utmPararms", utmPararms)
    return (
        <>
            <div className="no-good-time">לא מצאתם מועד שמתאים לכם? {!showButtons &&
                <span className="click" onClick={showCalendarButtons}>לחצו כאן</span>}</div>
            {showButtons && <div className="calendar-buttons">
                <button onClick={() => history.push('/noConvenientTime')}>אין מועד שמתאים לי</button>

                <button onClick={() => history.push('/meetingToday')}>רוצה להיפגש היום</button>

                <button onClick={() => history.push('/moreInfo')}>רוצה לקבל מידע נוסף</button>
            </div>}

            <section>
                <div className="calendar-wide-container" id="zoom-schedule">
                    <InlineWidget
                        styles={getStyles()}
                        url={getUrl()}
                        pageSettings={{
                            backgroundColor: "ffffff",
                            hideEventTypeDetails: false,
                            hideLandingPageDetails: false,
                            primaryColor: "00a2ff",
                            textColor: "4d5055",
                        }}
                        utm={{
                            utmCampaign: getSourceType(),
                            utmContent: Mixpanel.getId(),
                            utmMedium: getConcatenatedStringOfFeaturesForAnalytics(['landingPageVersion', 'mainHomeImage']),
                            utmSource: getCampaignValue(),
                            utmTerm: campaignName,
                            salesforce_uuid: JSON.stringify(utmPararms)
                        }}
                    />
                </div>
            </section>
        </>
    );
};

export default CalendarWide;
